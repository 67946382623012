.insExp:hover {
    color: #90caf9;
}

.r139qu9m7-0-0-beta-44::before {
    content: '';
    display: inline-block;
    height: auto;
    position: sticky;
    inset-inline-start: 0;
    border-inline-start: 2px solid var(--rdg-selection-color);
}

/* Directly target rows with aria-selected attribute */
.rdg-row[aria-selected="true"] {
    background-color: #6b59089c !important; /* Your desired selected row color */
}

