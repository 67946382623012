@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.slide-text {
    display: inline-block;
    color: yellow;
    padding-left: 100%; /* Push text to start from outside the visible area */
    animation: slide 20s linear infinite; /* Infinite loop, adjust the timing as needed */
}

.textclr-red {
    color: rgb(247, 43, 43);
}
.textclr-blue{
    color: rgb(84 255 249) ;
    /* animation: color-change .5s infinite !important; */
}
.textclr-pink{
    color: #f472b6;
}
.textclr-yellow{
    color: #ebc90a;
}
.textclr-green{
    color: #22c55e;
}