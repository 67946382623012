
.textclr-red {
    color: #f72b2b;
}
.textclr-blue{
    color: rgb(84 255 249) ;
    /* animation: color-change .5s infinite !important; */
}
.textclr-pink{
    color: #f472b6;
}
.textclr-yellow{
    color: #ebc90a;
}
.textclr-green{
    color: #22c55e;
}

@keyframes color-change {
    0% { color: red !important; }
    50% { color: blue !important; }
    100% { color: red !important; }
}

/* #root > div > div > div > div > div > div.MuiDataGrid-virtualScroller.css-qvtrhg-MuiDataGrid-virtualScroller{
    overflow: auto !important;
} */

.hov-row:hover {
    background-color: rgba(88, 121, 214, 0.6) !important;
    font-weight: 700;
}

.expiry-col {
    cursor: pointer;
    border: .1px solid hotpink;
    border-radius: 10%;
    text-decoration: underline;
}

.portfolio-col {
    cursor: pointer;
    text-decoration: underline;
}

.hide-checkbox div.MuiDataGrid-cellCheckbox  { 
    display: none !important;
}
.hide-checkbox div.MuiDataGrid-columnHeaderCheckbox  { 
    display: none !important;
}

.MuiDataGrid-row.Mui-selected {
    background-color: rgb(227 33 201 / 61%) !important;
}

::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
/* .MuiDataGrid-row {
    color: rgb(215, 135, 222) !important;
} */
