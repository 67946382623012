.home-floating-menu-left{
    position: fixed;
    top: 25px !important;
    left: 15px !important;
    display: flex;
    margin: 0;
    padding-top: 0.4rem ;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 1301;
    justify-content: space-around;
    align-items: flex-start;
}