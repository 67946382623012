.textclr-opt-red{
    color: rgb(247, 43, 43);
}
.textclr-opt-green{
    color: rgb(34, 197, 94);
}
.textclr-opt-yellow{
    color: rgb(255, 255, 0);
}
.chain-focus{
    background-color: #a7600c54;
}