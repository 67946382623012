.textclr-red {
    color: #f72b2b;
}
.textclr-yellow{
    color: #ebc90a;
}
.textclr-green{
    color: #22c55e;
}
.resizable-modal {
    resize: both;
    overflow: auto;
  }